import { useFetch } from 'hooks/useFetch';
import React, { useEffect } from 'react'
// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Row,
    Spinner
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
  import {NotificationContainer, NotificationManager} from 'react-notifications';
  import { Link } from 'react-router-dom';
export default function ViewAllShows() {
    const {data, isPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/show/getAllShowsAdmin');

    useEffect(()=>{
      if(error){
        NotificationManager.error(error, 'Error', 3000);
      }
    },[error])

  return (
    <div className="container pt-8">
      <NotificationContainer />
        <Row>
          <div className="col">
            {<Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">All Shows</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Venue</th>
                    <th scope="col">Start date</th>
                    <th scope="col">End date</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.shows.map((show)=>(<tr key={show.show_id}>
                    <th scope="row">
                     <Link to={`/admin/show-details/${show.show_id}`}>{show.show_name}</Link>
                    </th>
                    <td>{show.show_venue}</td>
                    <td>
                      {new Date(show.show_start_date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric'})}
                    </td>
                    <td>
                      {new Date(show.show_end_date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric'})}
                    </td>
                    <td>
                      {show.show_desc.split(' ').slice(0, 10).join(' ')}...
                    </td>
                  </tr>))}
                  {isPending && <tr className='d-flex justify-content-center align-items-center p-4'><td><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></td></tr>}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>}
          </div>
        </Row>
    </div>
  )
}
